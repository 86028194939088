<script>
import {ordersChart} from '@/components/Charts/Chart'
import Earth from '../../Widgets/Earth/Earth'
import {faker} from '@faker-js/faker'
import ESGLineChart from '../../Widgets/Charts/LineChart.vue'
import CompaniesGrid from '../../Widgets/CompaniesGrid.vue'
import ArticlesTable from '../Feed/ArticlesTable'
import {sortBy} from 'lodash'
import {CheckFactory} from "@/entities/CheckFactory";
import {Bing} from "@/entities/Bing";
import {MIN_SENTIMENT_SCORE} from "@/util/min-sentiment-score";

export default {
    name: 'Dashboard',
    components: {
        earth: Earth,
        CompaniesGrid,
        'esg-line-chart': ESGLineChart,
        ArticlesTable
    },

    data: function () {
        return {
            showDrawer: false,
            drawerBings: [],
            drawerWeek: null,
            drawerContinent: null,
            drawerCountry: null,

            checks: [],
            checksByWeek: [],
            warnChecks: {},
            warnChecksByCountry: {},

            bings: [],

            filter: {
                country: null,
                company: null,
            },

            pending: false,
            warnChecksPending: false,
            statsPending: false,

            fakeData: {
                alerts: [],
                overallPortfolioScore: 58,
                weeksScanned: 0,

            },
            stats: {
                companiesInPortfolio: 0,
            },
            countries: [],
            companies: [],
            salesChartID: "salesChart",
            ordersChartID: "ordersChart",
            trends: [
                {
                    name: 'Environment',
                    abbr: 'Env.',
                    color: '#75cb0c',
                    value: faker.datatype.number({min: -100, max: 100})
                },
                {name: 'Social', abbr: 'Soc.', color: '#cb0c9f', value: faker.datatype.number({min: -100, max: 100})},
                {
                    name: 'Governance',
                    abbr: 'Gov.',
                    color: '#3A416F',
                    value: faker.datatype.number({min: -100, max: 100})
                },
            ]
        };
    },

    created() {
        this.init()
    },

    methods: {
        async init() {
            this.pending = true
            await this.setCountries()
            await Promise.all([
                this.setStats(),
                this.setWarnChecks(),
                this.setMapWarnBings(),
            ])
            this.shuffle()
            this.pending = false
        },

        async setMapWarnBings() {
            let {data: bings} = await this.$store.getters.request('esg-plausibility-check/bings/warning')
            this.bings = bings
                .map(i => new Bing(i, null, null, {countries: this.countries}))
                .filter(i => i.country)

            this.warnChecksByCountry = this.bings.reduce((total, i) => {
                return {...total, [i.country.iso2]: i}
            }, {})
        },

        async setWarnChecks() {
            this.warnChecksPending = true
            const filter = {min_sentiment_score: {$lte: MIN_SENTIMENT_SCORE}}
            let {data: {data: checks}} = await this.$store.getters.request(`esg-plausibility-check?page=0&perPage=20&sort=min_sentiment_score&sortDir=desc&filter=${encodeURIComponent(JSON.stringify(filter))}`)
            this.warnChecksPending = false
            return this.warnChecks = checks
        },

        async setStats() {
            this.statsPending = true
            const {data: stats} = await this.$store.getters.request('stats')
            this.statsPending = false
            return this.stats = stats
        },

        async setCountries() {
            const {data: countries} = await this.$store.getters.request('country', 'get')
            return this.countries = countries.map(i => ({
                ...i,
                flag: i.alpha2Code.toLowerCase(),
                name: i.name,
                continent: i.region
            }))
        },

        async showCheckWarning(warningCheck) {
            warningCheck.pending = true
            console.log(warningCheck)
            let {data: checks} = await this.$store.getters.request(`esg-plausibility-check/by-companies?companies=${warningCheck.company_name}`)
            if (checks.length) {
                const company = {company_name: checks[0].company_name, checks}
                const check = await CheckFactory.createPlausibilityCheck(company, this.countries)
                this.drawerCountry = null
                this.drawerContinent = null
                this.drawerWeek = null
                this.drawerBings = sortBy(check.bings.filter(i => i.resultInPercents < 58), 'createdAt')
                setTimeout(() => {
                    this.showDrawer = true
                    warningCheck.pending = false
                }, 100)
            }
        },

        showWeekWarnings(week) {
            this.drawerCountry = null
            this.drawerContinent = null
            this.drawerWeek = week.label
            this.drawerBings = sortBy(week.warnChecks, 'createdAt')
            setTimeout(() => this.showDrawer = true, 300)
        },

        earthContinentClicked({continent}) {
            this.drawerCountry = null
            this.drawerWeek = null
            this.drawerContinent = continent
            this.drawerBings = this.bings.filter(i => i.country?.continent === continent.name)
            setTimeout(() => this.showDrawer = true, 300)
        },

        earthCountryClicked({country: {iso2, name}}) {
            this.drawerContinent = null
            this.drawerWeek = null

            // const filterCountry = this.countries.find(i => i.name === name)
            // if (filterCountry) {
            //   this.filter.country = filterCountry.name
            //   this.shuffle()
            // }

            if (this.warnChecksByCountry[iso2]) {
                const {country} = this.warnChecksByCountry[iso2]
                this.drawerCountry = country
                this.drawerBings = this.bings.filter(i => i.country.iso2 === iso2)
                setTimeout(() => this.showDrawer = true, 300)
            }
        },

        shuffle() {
            this.pending = true

            setTimeout(() => {
                if (this.$refs?.esgInTime?.redraw) {
                    this.$refs.esgInTime.redraw()
                }

                this.fakeData.alerts = [
                    faker.datatype.boolean() ? {
                        score: faker.datatype.number({min: 0, max: 100}),
                        title: `${faker.company.companyName()} Board Leadership`
                    } : null,
                    faker.datatype.boolean() ? {
                        score: faker.datatype.number({min: 0, max: 100}),
                        title: `${faker.company.companyName()} Non Discrimination`
                    } : null,
                    faker.datatype.boolean() ? {
                        score: faker.datatype.number({min: 0, max: 100}),
                        title: `${faker.company.companyName()} Anti-Competitive Behavior`
                    } : null
                ].filter(i => i)

                this.pending = false
            }, 1750)
        }
    },
};
</script>

<template src="./Dashboard.html"></template>
<style></style>
