<template>
  <div id="earth-js" class="earth-js"></div>
</template>

<script>
import countries from './countries.json'
import continents from './continents.json'
import {uniq} from 'lodash'

export default {
  props: {
    checks: {type: Object, default: () => ({})}
  },

  data() {
    return {
      myearth: null,
      localNewsMarker: null,
      news: [],
      bings: [],
      warnings: []
    }
  },

  watch: {
    checks(checks) {
      this.bings = checks
      this.warnings.map(i => this.myearth.removeLayer(i))
      this.warnings = this.addOverlays()
      this.updateMapStyles()
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    addOverlays() {
      const warnings = []

      if (this.bings) {
        const warningContinents = uniq(Object.keys(this.bings).map(key => {
          const continent = continents[this.bings[key].country.continent]
          if (!continent) {
            console.error(`Globe: Continent ${this.bings[key].country.continent} not found!`)
          }
          return continent
        })).filter(i => i)

        for (const continent of warningContinents) {
          const warning = this.myearth.addOverlay({
            location: {lat: continent.lat, lng: continent.lng},
            offset: 0.3,
            depthScale: 0.25,
            className: 'warning',
            occlude: false,
            continent: continent
          })
          warning.element.addEventListener('click', e => this.highlightBreakingNews(e))
          warnings.push(warning)
        }
      }
      return warnings
    },

    init() {
      const _this = this

      this.myearth = this.createEarth()

      this.myearth.addEventListener('ready', function () {
        this.startAutoRotate()

        // _this.myearth.addLine({
        //   polyLine : true,
        //   locations: [
        //     {lat: 50, lng: 100},
        //     {lat: 43, lng: 100},
        //     {lat: 43, lng: 96},
        //     {lat: 46, lng: 90},
        //     {lat: 50, lng: 90},
        //     {lat: 50, lng: 100}
        //   ],
        //   color : "red",
        //   width: 0.75
        // })

        // _this.myearth.addMarker({
        //   location: {lat: 3.52, lng: 97.3},
        //   mesh : "Pin3",
        //   color : "red",
        //   scale: 0.4,
        //   hotspot: false,
        // })
      })

      _this.myearth.addEventListener('click', function (event) {
        if (event.id) {
          const iso2 = event.id;
          const name = countries[iso2]
          _this.$emit('countryClicked', {country: {iso2, name}})

          // if (!_this.localNewsMarker) {
          //   _this.localNewsMarker = this.addMarker( {
          //     mesh : "Marker",
          //     color: '#257cff',
          //     location : event.location,
          //     scale: 0.01,
          //     opacity: 0
          //   })
          //
          //   _this.localNewsMarker.animate( 'scale', 0.9, { easing: 'out-back' } );
          // } else {
          //   _this.localNewsMarker.animate( 'location', event.location, { duration: 200, relativeDuration: 50, easing: 'in-out-cubic' } );
          // }

          _this.myearth.goTo(event.location, {duration: 250, relativeDuration: 70})
        }
      })
    },

    updateMapStyles() {
      let countryCodes = ''
      if (this.bings) {
        countryCodes = Object.keys(this.bings).map(key => `#${key.toUpperCase()}`).join(', ')
      }
      this.myearth.options.mapStyles = `${countryCodes} { fill: red; stroke: red; } `
      this.myearth.redrawMap()
    },

    createEarth() {
      return new Earth( document.getElementById('earth-js'), {
        location : {lat: 40, lng: 40},
        zoom: 1.2,
        light: 'none',
        transparent: true,
        mapSeaColor: 'RGBA(255,255,255,0.76)',
        mapLandColor: '#606060',
        mapBorderColor: '#9f9f9f',
        mapBorderWidth: 0.25,
        mapStyles: `{ fill: red; stroke: red; } `,
        mapHitTest: true,
        autoRotate: true,
        autoRotateSpeed: 0.99,
        autoRotateDelay: 4000,
      })
    },

    highlightBreakingNews(event) {
      const overlay = event.target.closest('.earth-overlay').overlay
      this.myearth.goTo(overlay.location, {duration: 250, relativeDuration: 70})
      this.continentClicked(overlay.continent)
      event.stopPropagation()
    },

    gotoBreakingNews(newsId) {
      this.myearth.goTo(this.news[newsId].location, {duration: 250, relativeDuration: 70});
    },

    continentClicked(continent) {
      // const bings = Object.keys(this.bings).reduce((all, key) => [...all, ...this.bings[key].bings], [])
      // // console.log(bings)
      // const continentBings = bings.filter(i => i.country.continent === continent.name)
      //
      // console.log(bings)
      // console.log(continentBings)
      this.$emit('continentClicked', {continent})
    }
  },
}
</script>

<style>
.earth-js {
  margin-top: 4rem;
}

.v-application .earth-overlay .warning {
  background-color: unset !important;
  border-color: transparent !important;
}
</style>
